<template>
  <div id="pie" style="width: 900px; height: 1000px"></div>
</template>

<script>
import request from "@/utils/request";
import * as echarts from "echarts";

export default {
  name: "echart_pie",
  data() {
    return {
    }
  },
  mounted() {  // 页面元素渲染之后再触发
  },
  methods: {
    setpie(){
      // 饼图
      var pieOption = {
        title: {
          text: '各季度加工数量统计',
          subtext: '比例图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [],

      };
      var pieDom = document.getElementById('pie');
      var pieChart = echarts.init(pieDom);
      for (let i = 0; i < this.facteryList.length; i++) {
        var x = ((i%3) * 2 + 1) * 14
        var y = parseInt(parseInt((i/3)+1)/(parseInt(this.facteryList.length/3)+2)*100)
        pieOption.series.push(
            {
              name: this.facteryList[i],
              type: 'pie',
              radius: '20%',
              center: [x+'%', y+'%'],
              label:{            //饼图图形上的文本标签
                normal:{
                  show:true,
                  position:'inner', //标签的位置
                  textStyle : {
                    fontWeight : 300 ,
                    fontSize : 14,    //文字的字体大小
                    color: "#000000"
                  },
                  formatter:'{a}:{c}({d}%)'
                }
              },
              data: [],  // 填空
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
        )
      }
      this.request.post("/echarts/members",{s: this.value2,facteryList: this.facteryList}).then(res => {
        for (let i = 0; i < pieOption.series.length; i++) {
          if (res.data[i][0] === 0 && res.data[i][1] === 0 && res.data[i][2] === 0 && res.data[i][3] === 0){
            pieOption.series.splice(i,1);
            i--
          }else {
            pieOption.series[i].data = [
              {name: "第一季度", value: res.data[i][0]},
              {name: "第二季度", value: res.data[i][1]},
              {name: "第三季度", value: res.data[i][2]},
              {name: "第四季度", value: res.data[i][3]},
            ]
          }
        }
        pieChart.setOption(pieOption)
      })
    },
  }
}
</script>

<style scoped>

</style>